import type { ComponentPropsWithRef } from 'react';
import type { RaceLiterval } from '@/types/nftAttribute';

import tw from 'twin.macro';

import IconRaceAISpectre from '../../svgs/species-aispectre.svg';
import IconRaceCyborg from '../../svgs/species-cyborg.svg';
import IconRacePandroid from '../../svgs/species-pandroid.svg';
import IconRaceXGene from '../../svgs/species-xgene.svg';

type SpeciesIconProps = ComponentPropsWithRef<typeof IconRaceAISpectre> & {
  race: RaceLiterval
}

export function SpeciesIcon({ race, ...props }: SpeciesIconProps) {
  return (
    <>
      {(race === 'AISpectre' || race === 'AI Spectre') && <IconRaceAISpectre {...props} />}
      {race === 'Cyborg' && <IconRaceCyborg {...props} />}
      {race === 'Pandroid' && <IconRacePandroid {...props} />}
      {(race === 'XGene' || race === 'X-Gene') && <IconRaceXGene {...props} />}
    </>
  )
}