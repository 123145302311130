import type { ComponentPropsWithRef } from 'react';
import type { CareerLiterval } from '@/types/nftAttribute';

import tw from 'twin.macro';

import IconCareerHackerWizard from '../../svgs/career-hacker-wizard.svg';
import IconCareerHardwareDruid from '../../svgs/career-hardware-druid.svg';
import IconCareerRoboWarrior from '../../svgs/career-robo-warrior.svg';
import IconCareerTradeNegotiator from '../../svgs/career-trade-negotiator.svg';
import IconCareerWeb3Monk from '../../svgs/career-web3-monk.svg';

type CareerIconProps = ComponentPropsWithRef<typeof IconCareerHackerWizard> & {
  race: CareerLiterval
}

export function CareerIcon({ career, ...props }: CareerIconProps) {
  return (
    <>
      {career === 'HackerWizard' && <IconCareerHackerWizard {...props} />}
      {career === 'HardwareDruid' && <IconCareerHardwareDruid {...props} />}
      {career === 'RoboWarrior' && <IconCareerRoboWarrior {...props} />}
      {career === 'TradeNegotiator' && <IconCareerTradeNegotiator {...props} />}
      {career === 'Web3Monk' && <IconCareerWeb3Monk {...props} />}
    </>
  )
}