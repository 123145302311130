// nft type
export enum NFTType {
  OriginShell = 1,
  Shell = 2
}

// nft race
export enum RaceEnum {
  Cyborg = 'Cyborg',
  Pandroid = 'Pandroid',
  XGene = 'X-Gene',
  AISpectre = 'AI Spectre'
}

export const RACE_CHAIN_MAP = {
  [RaceEnum.Cyborg]: 'Cyborg',
  [RaceEnum.Pandroid]: 'Pandroid',
  [RaceEnum.XGene]: 'XGene',
  [RaceEnum.AISpectre]: 'AISpectre'
};

export const RACE_TYPE_CHAIN_MAP = {
  [RACE_CHAIN_MAP[RaceEnum.Cyborg]]: RaceEnum.Cyborg,
  [RACE_CHAIN_MAP[RaceEnum.Pandroid]]: RaceEnum.Pandroid,
  [RACE_CHAIN_MAP[RaceEnum.XGene]]: RaceEnum.XGene,
  [RACE_CHAIN_MAP[RaceEnum.AISpectre]]: RaceEnum.AISpectre
};

export const RACE_LIST: RaceEnum[] = [RaceEnum.AISpectre, RaceEnum.Cyborg, RaceEnum.Pandroid, RaceEnum.XGene];

// nft career
export enum CareerEnum {
  HardwareDruid = 'Hardware Druid',
  RoboWarrior = 'Robo Warrior',
  TradeNegotiator = 'Trade Negotiator',
  HackerWizard = 'Hacker Wizard',
  Web3Monk = 'Web3.0 Monk'
}

export const CAREER_CHAIN_MAP: Record<CareerEnum, CareerLiterval> = {
  [CareerEnum.HardwareDruid]: 'HardwareDruid',
  [CareerEnum.RoboWarrior]: 'RoboWarrior',
  [CareerEnum.TradeNegotiator]: 'TradeNegotiator',
  [CareerEnum.HackerWizard]: 'HackerWizard',
  [CareerEnum.Web3Monk]: 'Web3Monk'
};

export const CAREER_TYPE_CHAIN_MAP = {
  [CAREER_CHAIN_MAP[CareerEnum.HardwareDruid]]: CareerEnum.HardwareDruid,
  [CAREER_CHAIN_MAP[CareerEnum.RoboWarrior]]: CareerEnum.RoboWarrior,
  [CAREER_CHAIN_MAP[CareerEnum.TradeNegotiator]]: CareerEnum.TradeNegotiator,
  [CAREER_CHAIN_MAP[CareerEnum.HackerWizard]]: CareerEnum.HackerWizard,
  [CAREER_CHAIN_MAP[CareerEnum.Web3Monk]]: CareerEnum.Web3Monk
};

export const CAREER_LIST: CareerEnum[] = [
  CareerEnum.HardwareDruid,
  CareerEnum.RoboWarrior,
  CareerEnum.TradeNegotiator,
  CareerEnum.HackerWizard,
  CareerEnum.Web3Monk
];

// nft level
export enum LevelEnum {
  Legendary = 'Legendary',
  Magic = 'Magic',
  Common = 'Prime Edition',
  Prime = 'Prime Edition'
}

export const LEVEL_CHAIN_MAP = {
  [LevelEnum.Legendary]: 'Legendary',
  [LevelEnum.Magic]: 'Magic',
  [LevelEnum.Common]: 'Prime',
  [LevelEnum.Prime]: 'Prime'
};

export const LEVEL_TYPE_CHAIN_MAP = {
  [LEVEL_CHAIN_MAP[LevelEnum.Legendary]]: LevelEnum.Legendary,
  [LEVEL_CHAIN_MAP[LevelEnum.Magic]]: LevelEnum.Magic,
  [LEVEL_CHAIN_MAP[LevelEnum.Common]]: LevelEnum.Common
};

export const LEVEL_COLOR_MAP: Record<LevelEnum, string> = {
  [LevelEnum.Legendary]: 'rgba(248, 136, 27, 1)',
  [LevelEnum.Magic]: 'rgba(137, 27, 248, 1)',
  [LevelEnum.Common]: 'rgba(54, 121, 250, 1)'
};

// nft size
export enum SizeEnum {
  Large = 'L',
  Medium = 'M',
  Small = 'S'
}

// spirit attribute
export enum AttributeEnum {
  Dexterity = 'Dexterity',
  Willpower = 'Willpower',
  Intelligent = 'Intelligence',
  Strength = 'Strength'
}

export const SERVER_ATTR_MAP = {
  [AttributeEnum.Intelligent]: 'intellect',
  [AttributeEnum.Dexterity]: 'dexterity',
  [AttributeEnum.Strength]: 'strength',
  [AttributeEnum.Willpower]: 'willpower'
};

export const ATTRIBUTE_LIST: AttributeEnum[] = [
  AttributeEnum['Dexterity'],
  AttributeEnum['Willpower'],
  AttributeEnum['Intelligent'],
  AttributeEnum['Strength']
];

// platform
export enum PlatformEnum {
  Discord = 'discord',
  Twitter = 'twitter',
  Github = 'github',
  Forum = 'discourse-phala',
  ETH = 'evm',
  BSC = 'evm',
  Khala = 'substrate',
  Kusama = 'substrate',
  PolkaDot = 'substrate'
}

// purchase
export enum PurchaseEnum {
  Rare = 1,
  Hero,
  Preorder
}

export type RaceLiterval = (keyof typeof RaceEnum) & string;

export type LevelLiterval = (keyof typeof LevelEnum) & string;

export type CareerLiterval = (keyof typeof CareerEnum) & string;

export type RarityLiterval = LevelLiterval;

export type SpeciesLiterval = RaceLiterval;

export const RarityLitervalEnum = LEVEL_TYPE_CHAIN_MAP

export type ItemRarityLiterval = ('Normal' | 'Rare' | 'Epic' | 'Legend') & string